<template>
  <div class="mm-main-page">
    <Header
      :dark="isHomePage"
      change-color 
      :links="mainHeaderLinks"
      :hide-search="!isSearchGroupAccess"
    />
    <slot />
    <LazyMobileMenu v-if="isHomePage" />
    <Footer />
    <GTM />
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import Header from 'components/layouts/default/Header.vue';
import Footer from 'components/layouts/default/Footer.vue';
import { useAuthModal, useServerSsoAuth } from 'composables/useAuthHelper';
import { useSearchGroupAccess } from 'composables/useGroup';
import { useMainTitle } from 'composables/useMainTitle';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';
import { useCompareProducts } from '../composables/useCompareProducts';
import { useRefreshBasketData } from 'composables/useRefreshBasketData';
import { useMainHeaderLinks } from 'composables/useMainHeaderLinks';

setAuthCookieHeaders();
await useDomainAuth();

useMainTitle();

await useServerSsoAuth();
await useSetServerUserInfo();
useSetClientUserInfo();
useAuthModal();
useCheckInternalUser();
useRefreshBasketData();

const isSearchGroupAccess = useSearchGroupAccess();

const route = useRoute();

useCompareProducts();

const { mainHeaderLinks } = useMainHeaderLinks();

enum EPath {
  Postavschikam = '/postavschikam/',
  Pokupatelyam = '/pokupatelyam/',
  About = '/about/',
  Contact = '/contact/'
}

const isHomePage = computed(() => ![EPath.Pokupatelyam, EPath.Postavschikam, EPath.About, EPath.Contact ].includes(route.path));
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  :deep(.mm-god-mode-header) {
    margin-top: -8px;
    border-radius: 6px;
    max-width: 1584px;
  }

  &.overflow {
    overflow: hidden;
  }

  :deep(.mm-header) {
    padding: 8px 8px 0;
    background: $white;
    position: relative;

    .header--dark {
      background: $dark-header-bg;
      border-radius: 6px 6px 0 0;
    }

    .mm-catalog-popup {
      width: calc(100% + 16px);
      left: -8px;
      z-index: 10;

      .mm-catalog-popup-inner__left {
        &::before {
          content: '';
          position: absolute;
          height: calc(100% + 50px);
          width: 50vw;
          top: -50px;
          left: 0;
          z-index: -1;
        }
      }

      .mm-catalog-popup-inner__right {
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 50px;
          background: $white;
          top: -50px;
          left: 0;
          z-index: -1;
        }
      }
    }
  }

  :deep(.header__search) {
    .search__col {
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-main-page {
    :deep(.mm-header) {
      margin: 0;
      padding: 0;
      border-radius: 6px 6px 0 0;
    }

    :deep(.mm-footer) {
      margin: 4px;
      width: calc(100% - 8px);
      margin-bottom: 53px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-main-page {
    :deep(.mm-header) {
      margin: 0;
      border-radius: 6px 6px 0 0;
      padding: 0;

      .mm-select__wrapper {
        display: none;
      }
    }

    :deep(.mm-footer) {
      margin: 4px;
      width: calc(100% - 8px);
    }
  }
}
</style>
